<template>
  <div>
    <div class="m-switch-companies">
      <div class="title-big">Changer de société</div>
      <div v-if="user">
        <div>
          <p>{{ user.contact.firstname }} {{ user.contact.lastname }}</p>
          <p>{{ user.contact.email }}</p>
          <br>
        </div>
        <select v-model="params.active" @change="changeCompany">
          <option v-if="isCaci" value="caci" selected disabled>Mon compte CACI</option>
          <option v-else :value="this.params.active" selected disabled>{{ params.active ? params.active.brand : '' }}</option>
          <option v-for="company in this.uniqueCompanies" :value="company" v-if="isCaci || company.company.brand !== params.active.brand">
            {{ company ? company.company.brand : '' }}
          </option>
        </select>
      </div>
      <div v-else>
        <p>User not logged in</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "switch-companies",
  props: ['data','interface'],
  data(){
    return{
      contacts: [],
      loading: false,
      permissions: parseInt(process.env.VUE_APP_PERMISSIONS),
      count: -1,
      params:{
        limit : 12,
        offset: 0,
        search: '',
        active: true
      },
      companies: [],
      uniqueCompanies: [],
      selectedCompany: null
    }
  },
  mounted() {
    this.uniqueCompanies = this.getUniqueCompanies();
    this.params.active = this.isCaci ? "caci" : this.user.company;
  },
  methods: {
    changeCompany() {
      if(this.params.active=="caci") return;
      Vue.http.headers.common['X-Switch-User'] = String(this.params.active.id);
      localStorage.setItem('current_company', String(this.params.active.id));
      localStorage.setItem("switched-from-caci", true);
      window.location.reload();
    },
    getUniqueCompanies() {
      const ids = new Set();
      const uniqueCompanies = this.companies.filter(company => {
        if (!company.company || !company.company.can_create_account || ids.has(company.company.id)) {
          return false;
        }
        ids.add(company.company.id);
        return true;
      });
      return uniqueCompanies;
    },
  },
  computed:{
    user(){
      const user = this.$user();
      if (user) {
        this.$store.getters.attachedAccounts().filter((attachedAccount)=>attachedAccount.company).forEach((account)=>{
          this.companies.push(account)
        })
      }
      return user;
    },
    isCaci(){
      return this.$user().isCommercialAgent;
    },
    checkup(){
      return this.$store.getters.checkup()
    },
  },
}
</script>

<style lang="scss">
@import '../../environment';
.o-user-select-company {
  select {
    width: 50%;
  }
}
</style>