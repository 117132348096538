<template>
  <main class="v-page">
    <loader v-if="loading" static="1" tall="1"></loader>
    <component
      v-else-if="formation"
      :is="item.component"
      :data="data"
      :interface="item.data"
      v-for="(item, index) in page.components"
      :key="index"
    />
    <h2 class="title" v-else-if="!formation">La formation n'existe pas.</h2>
  </main>
</template>
<script>
import Interface from "@/interface.yml";
import formationCourseRepository from "@/repositories/formationCourseRepository";

export default {
  data() {
    return {
      json: Interface,
      page: false,
      formation: false,
      formations: false,
      loading: true,
      selectedFormationIds: [],
      hoursToReach: 42,
    };
  },
  computed: {
    selectedFormations(){
      return this.selectedFormationIds.map((formationId) =>
        this.formations.find(
          (formation) => formation.id === formationId
        )
      );
    },
    totalHours() {
      return this.selectedFormations.reduce(
        (total, formation) => (total += formation.formation.duration.hours),
        this.formation.formation.formation_required.duration.hours
    ) ;
    },
    isComplete() {
      return this.totalHours >= this.hoursToReach;
    },
    data() {
      return {
        ...this.formation,
        ...this.formation.formation,
        formations: this.formations,
        selectedFormations: this.selectedFormations,
        totalHours: this.totalHours,
        isComplete: this.isComplete,
        onSelectFormation: this.handleSelectFormation,
        onUnselectFormation: this.handleUnselectFormation
      }
    },
    user() {
      return this.$user();
    },
  },
  methods: {
    handleSelectFormation(formationId) {
      if(!this.isComplete)
        this.selectedFormationIds.push(formationId);
    },
    handleUnselectFormation(formationId) {
      const index = this.selectedFormationIds.indexOf(formationId);
      this.selectedFormationIds.splice(index, 1);
    },
  },
  mounted() {
    this.page = this.json.pages[this.$route.name];
    const contacts = this.user.staff;

    Promise.all([
      formationCourseRepository.getPackFormation(),
      formationCourseRepository.getPackFormations()
    ]).then(
      async ([formationResponse, packFormationsResponse]) => {
        this.formation = formationResponse;
        const { condition } = await formationCourseRepository.get(this.formation.id)
        this.formation.taxRate = this.$user().tva;
        this.formation.condition = condition;
        this.formation.isPack = true;
        this.formations = packFormationsResponse.formation_course;
        formationCourseRepository.getDoneFormationsPerContact({formationCourseIds: this.formations.map(({id})=>id), contacts: contacts.map(({id})=>id)}).then((doneFormationsPerContactResponse)=>{
        this.formations = this.formations.map((formation)=>({
            ...formation,
            doneBy: Object.entries(doneFormationsPerContactResponse).reduce((doneBy, [contactId, doneFormationIds])=>{
              if(doneFormationIds.includes(formation.id))
                return [...doneBy, contacts.find(({id})=>contactId==id)]
              return doneBy;
            }, [])
          }))
          this.loading = false;
        })
      },
    );
  },
};
</script>
