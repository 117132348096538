<template>
  <div class="m-order-detail-pack">
    <div class="m-order-detail-pack__entry">
      <p class="primary"><b>Imposé</b></p>
      <ul>
        <li>1 X {{ mandatoryFormation }}</li>
      </ul>
    </div>
    <div class="m-order-detail-pack__entry">
      <p class="primary"><b>Selectionnés</b></p>
      <ul>
        <li v-for="selectedFormation in selectedFormations">
          1 X {{ selectedFormation }}
        </li>
      </ul>
    </div>
    <div class="m-order-detail-pack__entry">
      <p class="primary"><b>Collaborateurs inscrits</b></p>
      <ul>
        <li v-for="contact in contacts">
          {{ contact.firstname }} {{ contact.lastname }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderDetailsPack",
  props: ["data"],
  computed: {
    mandatoryFormation() {
      return this.data[1].title;
    },
    selectedFormations() {
      return this.data.slice(2).map(({ title }) => title);
    },
    contacts() {
      return this.data[0].contacts;
    },
  },
};
</script>

<style lang="scss">
@import '../../environment';

.m-order-detail-pack{
    display: flex; flex-direction: column; gap: $space-s;
    color: $c-primary;
    &__entry {
      ul {
        margin-top: $space-s;
      }
    }
}
</style>
