<template>
  <div class="m-user-summary" v-if="user">
    <div class="m-user-summary__avatar" >
      <img :src="user.contact.avatar" v-if="user.contact && user.contact.avatar"/>
      <img :src="user.company.logo" v-else-if="user.company && user.company.logo"/>
      <span class="m-user-summary__avatar-initial" v-else>
	      <span v-if="user.contact">{{ user.contact.firstname.substr(0,1) }}{{ user.contact.lastname.substr(0,1) }}</span>
	      <span v-else>{{ user.company.name.substr(0,1) }}</span>
      </span>
    </div>

    <div class="m-user-summary__infos">
      <div class="m-user-summary__user" v-if="user.contact">
        <b>{{ user.contact.firstname }} {{ user.contact.lastname }}</b><br/>
        <span v-if="user.contact.memberId && !hasSwitchedFromCaci">N&deg; adhérent : {{ user.contact.memberId }}</span>
      </div>
      <div class="m-user-summary__user" v-if="user.company">
        <b v-if="user.company" class="text-uppercase">{{ user.company.brand }}</b><br/>
        <span v-if="user.company.memberId">N&deg; adhérent : {{ user.company.memberId }}</span>
      </div>
      <div class="m-user-summary__last desktop" v-if="user.lastLogin">Dernière connexion le {{ formatDigitDate(user.lastLogin)}}</div>
      <div class="m-user-summary__links">
        <router-link :to="'/user/'+item.url" class="m-user-summary__link" v-for="(item, index) in navigation.user.children" :key="index" v-if="navigation && navigation.user && checkRole(user, item)">
          {{ item.title }}
        </router-link>
        <a href="javascript:void(0);" @click="backToCaciAccount" class="m-user-summary__link" v-if="hasSwitchedFromCaci">Retour sur mon compte CACI</a>
        <a href="javascript:void(0);" @click="showSocieties" class="m-user-summary__link" v-else-if="canSwitch">Changer de société</a>
      </div>

      <div class="m-user-summary__logout" data-icon_after="logout" @click="logOut" title="Déconnexion"></div>
    </div>

  </div>
</template>

<script>
import userRepository from '@/repositories/userRepository';
import Vue from "vue";

export default {
  name: "user-summary",
  methods:{
    logOut(){
      userRepository.logout();
    },
    showSocieties() {
      this.$popin("switch-companies");
    },
    backToCaciAccount(){
      localStorage.removeItem("switched-from-caci");
      localStorage.removeItem('current_company');
      Vue.http.headers.common["X-Switch-User"] = undefined;
      window.location.reload();
    }
  },
  computed:{
    user(){
      return this.$user()
    },
    navigation() {
      return this.$store.getters.navigation()
    },
    canSwitch() {
      // todo gérer le cas des CACI (checker en plus si la company est multi-co)
      if (((this.user.isLegalRepresentative === true) && (this.hasManyCompaniesAddresses === true)) || (this.user.isCommercialAgent && this.hasCollaboratorAccounts)) {
        return true;
      }
      return false;
    },
    hasManyCompaniesAddresses() {
      let companies = this.user.contact.addresses.reduce((acc ,address) => {
        let companyId = address.company ? address.company.id : null;
        if ((companyId !== null) && (!acc[companyId])) {
          acc[companyId] = address;
        }
        return acc;
      }, {});

      return (Object.keys(companies).length > 1);
    },
    hasCollaboratorAccounts(){
      return this.$store.getters.attachedAccounts().filter((attachedAccount)=>attachedAccount.company).length>0;
    },
    hasSwitchedFromCaci(){
      return localStorage.getItem("switched-from-caci");
    },
  },
}
</script>

<style lang="scss">
@import '../../environment';
.m-user-summary{

  @media #{$to-tablet}{
    display: flex; background: $c-background; padding: $space-m; color: $c-primary;

    &__infos{ flex: 1; text-align: left; margin-left: $space-m; position: relative }
  }

  &__last{ color: $c-main; font-size: $font-s; margin-top: $space-s }

  &__avatar{
    width: 8rem; height: 8rem; margin: auto; border-radius: 50%; overflow: hidden; position: relative; background: $c-primary; margin-bottom: 1rem;
    img{ width: 100%; height: 100%; object-fit: cover; background: #fff; opacity: 0.9 }
    &-initial{
      position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center;
      align-items: center; color: #fff; font-size: 5rem; opacity: 0.15; text-transform: uppercase;
      @media #{$to-phone}{ font-size: 3rem }
    }
    @media#{$from-small}{
      & + *{ margin-top: $space-s }
    }
    @media #{$to-tablet}{ margin-top: 0; width: 6rem; height: 6rem; }
  }

  &__links{
    margin-top: $space;
    @media #{$to-tablet}{ margin-top: $space-s }
  }

  &__logout{
    position:absolute; right: $space-m; top: $space-m; margin: 0; font-size: $font-xl;
    @media #{$to-tablet}{ right: 0; top: 0 }
  }

  &__link{
    padding: $space-s 0; cursor: pointer; text-align: left; display: block;
    &:last-of-type{ padding-bottom: 0 }
    &+&{ border-top: solid 1px $c-border }
  }

  &__user{
    white-space: normal;
  }

  .button{
    margin-top: $space-m;
    @media #{$to-tablet}{ margin-top: $space-s }
  }
}

</style>