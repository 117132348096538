import Vue from "vue";
import store from "@/services/store";

export default{
    getAttachedAccounts(id){
        return new Promise((resolve, reject)=>{
            Vue.http.get(`caci/switch/${id}`).then((response)=>{
                store.commit("attachedAccounts", response.body.response.users)
                resolve();
            }).catch(reject)
        })
    },
}