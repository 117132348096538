import Vue from 'vue'
import Vuex from 'vuex'
import hash from 'object-hash';

Vue.use(Vuex);

import Interface from '@/interface.yml';

//todo: garbage collector for cached data
const default_data = function () {
  return {
    document:{},
    editable_document:{},
    appendix:{},
    news:{},
    formation_course:{
      filters:{}
    },
    formation_course_subscribed:{},
    formation_course_report:{},
    contact:{},
    company_contact:{},
    resource:{},
    collection_signature:{},
    contact_expert:{},
    legacy: false
  }
}

const default_state = function(){
  return {
    companies:[],
    packageVersion: process.env.PACKAGE_VERSION || '0',
    user:{
      id: false,
      metadata:{
        favorite:{
          appendix:[],
          resource:[]
        },
        read:{
          appendix:[],
          resource:[],
          tour:[]
        },
        pinned:{
          resource:[]
        }
      }
    },
    backgroundTask:[],
    representative:{},
    membership: false,
    podcast: false,
    data: default_data(),
    forms:{
      formations: false
    },
    checkup:[],
    notifications:[],
    google: false,
    navigation: Interface.menu,
    pages: Interface.pages,
    popin: [],
    alert: false,
    error: false,
    current: false,
    cart: {
      formation:{}
    },
    type: false,
    tour: false,
    config: false,
    current_time: false,
    current_time_interval: false,
    company: false,
    action: false,
    terms:[],
    changesSaved:true,
    pausedFoad: false,
    permissions: false,
    attachedAccounts: false
  }
};

const clean = function(obj) {

  if( typeof obj == 'undefined' )
    return {};

  let objCopy = JSON.parse(JSON.stringify(obj));

  for (let propName in objCopy) {

    if ( objCopy.hasOwnProperty(propName) && (objCopy[propName] === null || objCopy[propName] === undefined || objCopy[propName] === ''))
      delete objCopy[propName];
  }

  delete objCopy.offset;

  return objCopy;
};


let store = new Vuex.Store({
  state: default_state(),
  plugins: [
    //createPersistedState()
  ],
  getters: {
    pausedFoad: state => () =>{
      return state.pausedFoad;
    },
    appVersion: state => () =>{
      return state.packageVersion
    },
    currentTime: state => () =>{
      return state.current_time;
    },
    changesSaved: state => () =>{
      return state.changesSaved;
    },
    backgroundTask: state => () =>{
      return state.backgroundTask;
    },
    terms: state => () =>{
      return state.terms;
    },
    tour: state => () =>{
      return state.tour;
    },
    user: state => () =>{
      return state.user.id ? state.user : false;
    },
    legacy: state => () =>{
      return state.legacy;
    },
    metadata: state => () =>{
      return state.user.metadata;
    },
    membership: state => () =>{
      return state.membership;
    },
    podcast: state => () =>{
      return state.podcast;
    },
    action: state => () =>{
      return state.action;
    },
    companies: state => () =>{
      return state.companies;
    },
    data: state => (object) =>{

      let params = clean(object.params);
      let key = hash(params);
      let now = (new Date()).getTime();

      object.type = object.type.replace(/\//g, '_');

      //if( state[type].date+state[type].ttl < (new Date()).getTime() )
       // state[type].items = [];

      if( object.type in state.data && key in state.data[object.type])
        return state.data[object.type][key];
      else
        return false;
    },
    appendices: state => () =>{

      if( state.documents.date+state.documents.ttl < (new Date()).getTime() )
        state.documents.items = [];

      return state.documents;
    },
    checkup: state => () =>{
      return state.checkup;
    },
    notifications: state => () =>{
      return state.notifications;
    },
    google: state => () =>{
      return state.google;
    },
    navigation: state => () =>{
      return state.navigation;
    },
    pages: state => () =>{
      return state.pages;
    },
    popin: state => () =>{
      return state.popin;
    },
    alert: state => () =>{
      return state.alert;
    },
    error: state => () =>{
      return state.error;
    },
    form: state => (type) =>{
      return state.forms[type];
    },
    current: state => () =>{
      return state.current;
    },
    cart: state => (object) =>{
      if( object.type in state.cart && object.productId in state.cart[object.type])
        return state.cart[object.type][object.productId];
      else
        return false
    },
    type: state => () =>{
      return state.type;
    },
    config: state => () =>{
      return state.config;
    },
    permissions: state => () =>{
      return state.permissions;
    },
    attachedAccounts: state => () =>{
      return state.attachedAccounts;
    }
  },
  mutations:{
    config(state,config){

      state.config = config;
      state.current_time = config.timestamp

      clearInterval(state.current_time_interval);
      state.current_time_interval = setInterval(() => { state.current_time+= 1000; store.commit('currentTime',  state.current_time) }, 1000);
    },
    currentTime(state, current_time){
      state.current_time = current_time;
    },
    pausedFoad(state,pausedFoad){
      state.pausedFoad = pausedFoad;
    },
    changesSaved(state,changesSaved){
      state.changesSaved = changesSaved;
    },
    legacy(state,legacy){
      state.legacy = legacy;
    },
    terms(state,terms){
      state.terms = terms;
    },
    tour(state,tour){
      state.tour = tour;
    },
    action(state,action){
      state.action = action;
    },
    membership(state, membership){
      state.membership = membership;
    },
    podcast(state, podcast){
      state.podcast = podcast;
    },
    backgroundTask(state, task){
      if( task.state &&  state.backgroundTask.indexOf(task.type) === -1 )
        state.backgroundTask.push(task.type);

      if( !task.state && state.backgroundTask.indexOf(task.type) > -1)
        state.backgroundTask.splice(state.backgroundTask.indexOf(task.type), 1);
    },
    user(state, user)
    {

      let metadata = default_state().user.metadata;

      if( !user.isRegistering ){

        Vue.http.get('user/metadata').then(response => {
          response.body.response.forEach(element =>{
            metadata[element.state][element.type].push(element.entityId);
          });
          user.metadata = metadata;
          Vue.http.get('rate').then(response => {
            user.rates = response.body.response;
            state.user = user;
          });
        });
      }
      else{

        user.metadata = [];
        user.rates = [];
        state.user = user;
      }
    },
    companies(state, companies)
    {
      state.companies = companies;
    },
    data(state, object)
    {
      let params = clean(object.params);
      let key = hash(params);
      let now = (new Date()).getTime();

      if( typeof object.type == 'undefined' ){

        state.data = default_data();
        return;
      }

      object.type = object.type.replace(/\//g, '_');

      if( !object.data ){

        state.data[object.type] = {};
      }
      else{

        if( 'limit' in object.data && 'offset' in object.data && 'items' in object.data){

          state.data[object.type][key] = {
            date: now,
            params: params,
            count: object.data.count,
            offset: object.data.offset,
            limit: object.data.limit,
            items: JSON.parse(JSON.stringify(object.data.items))
          };
        }
        else{

          state.data[object.type][key] = {
            date: now,
            params: params,
            data: JSON.parse(JSON.stringify(object.data))
          };
        }
      }
    },
    appendices(state, appendices)
    {
      appendices.date = (new Date()).getTime();
      state.appendices = JSON.parse(JSON.stringify(appendices));
    },
    checkup(state, checkup)
    {
      state.checkup = checkup;
    },
    notifications(state, notifications)
    {
      state.notifications = notifications;
    },
    google(state, google)
    {
      state.google = google;
    },
    popin(state, popin)
    {
      if(popin.delete){
        state.popin = state.popin.filter(element => element.type !== popin.type)
      }
      else if(typeof popin.type !== 'undefined')
        state.popin.push(popin);
    },
    alert(state, alert)
    {
      state.alert = alert;
    },
    error(state, error)
    {
      state.error = error;
    },
    current(state, current)
    {
      state.current = current;
    },
    cart(state, object)
    {
      state.cart[object.type][object.productId] = object;
    },
    type(state,type)
    {
      state.type = type;
    },
    form(state,form)
    {
      state.forms[form.type] = form.params;
    },
    metadata(state, data)
    {
      if( data.entityId === ''){

        for (let i = 0; i < state.user.metadata[data.state][data.type].length; i++){

          let pos = state.user.metadata[data.state][data.type].indexOf(data.entityId);
          state.user.metadata[data.state][data.type].splice(pos,1);
        }
      }
      else{

        let pos = state.user.metadata[data.state][data.type].indexOf(data.entityId);

        if( pos === -1 )
          state.user.metadata[data.state][data.type].push(data.entityId);
        else
          state.user.metadata[data.state][data.type].splice(pos,1);
      }
    },
    permissions(state,permissions){
      state.permissions = permissions;
    },
    attachedAccounts(state, attachedAccounts){
      state.attachedAccounts = attachedAccounts
    }
  },
  actions: {
    notifications (context){
      context.commit('notifications', 3);
    },
    clear(context, type){
      context.commit('data', {
        type: type,
        data: false
      });
    }
  }
});

export default store;
