<template>
	<div class="m-training-external">
		<h2 class="title">Renseigner une formation externe</h2>
		<p class="m-training-external__subtitle">Pour comptabiliser les heures de formations externes au SNPI,<br/> veuillez renseigner les informations principales</p>
		<form @submit.prevent="submitForm" enctype="multipart/form-data" ref="form" id="formation_add-external">
			<vue-form-generator tag="div" :schema="form" :options="formOptions"></vue-form-generator>
			<submit class="m-training-external__submit" text="Ajouter" text-success="Ajouté" :loading="loading" :success="success"></submit>
		</form>
		<p class="m-training-external__mention">
			<b>Attention:</b> Avant d'ajouter cette formation au compteur de votre collaborateur,<br/>veuillez vous assurer que les heures que vous renseignez sont validantes loi ALUR.
		</p>
	</div>
</template>

<script>

	import externalFormationRepository from "@/repositories/externalFormationRepository";

	export default {
		name: "training-external",
		props:['data'],
		data(){

			return{
				loading: false,
				success: false,
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
				form: {
					groups: [
						{
							styleClasses: "main-fields fields-label form-group-background",
							fields: [
								{
									type: "input",
									inputType: "text",
									inputName: "title",
									required: true,
									label: "Titre de la formation"
								},
								{
									type: "input",
									inputType: "text",
									inputName: "address",
									required: true,
									label: "Nom de l'organisme de formation"
								},
								{
									type: "calendar-custom",
									inputName: "startAt",
									styleClasses: "fields-label",
									label: "Date de début",
									required: true,
									max: this.now(),
                  min: this.getDateFromYear(3, true)
								},
								{
									type: "calendar-custom",
									inputName: "endAt",
									styleClasses: "fields-label",
									label: "Date de Fin",
									required: true,
									max: this.now(),
                  min: this.getDateFromYear(3, true)
								},
								{
									type: "select",
									inputName: "format",
									styleClasses: "fields-label",
									required: true,
									label: "Type",
									selectOptions:{
										noneSelectedText: "---"
									},
									values: [
										{id:"instructor-led", name: "Présentiel"},
										{id:"e-learning", name:"En ligne"}
									]
								},
								{
									type: "input",
									inputType: "number",
									inputName: "hours",
									required: true,
									label: "Heures de formation",
									min: 1,
									max: 42,
									step: "any"
								},
								{
									type: "input",
									inputType: "number",
									inputName: "hoursEthics",
									required: true,
									label: "Dont déontologie",
									styleClasses:"fields-half",
									min: 0,
									max: 10,
									step: "any"
								},
								{
									type: "input",
									inputType: "number",
									inputName: "hoursDiscrimination",
									required: true,
									label: "Dont discriminations",
									styleClasses:"fields-half",
									min: 0,
									max: 10,
									step: "any"
								},
								{
									type: "upload",
									inputName: "certificate",
									styleClasses: "fields-label",
									required: true,
									accept:'application/pdf',
									label: "Attestation PDF"
								}
							]
						}
					]
				}
			}
		},
		methods:{
			submitForm(){
				let self = this;

				this.loading = true;
				let formData = new FormData( this.$refs.form );
				formData.append('contact', this.data);

        externalFormationRepository.create(formData).then(response => {

					this.loading = false;
					this.success = true;
	        this.$update();

					setTimeout(function () {
						self.$emit('close');
					}, 600);

				},response=>{
					this.loading = false;
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-training-external{

		.main-fields{ text-align: left }
		.field-small{
			display: inline-block;
			.field-wrap{ width: 8rem }
			& + .field-small{ margin-left: $space }
		}

		&__subtitle{ margin: $space 0 }
		&__mention{
			font-weight: bold; text-align: center; margin-top: $space; color: $c-primary;
			b{ color: $c-secondary }
		}
		&__submit{ margin-top: $space }
	}

</style>