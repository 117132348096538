<template>
  <div class="o-training-details" v-if="data">
    <!--		TODO back to same loaded trainings ?-->
    <a
      @click="$router.push('/formations')"
      data-icon="chevron-left"
      class="o-training-details__back"
      >retour</a
    >
    <h1 class="title">{{ interface.title }}</h1>

    <div class="o-training-details__content">
      <div class="o-training-details__heading">
        <div>
          <div
            class="o-training-details__date primary"
            v-if="data.format === 'instructor-led' || data.format === 'webinar'"
          >
            {{ formatDate(data.startAt) }}
            <span v-if="data.endAt && data.endAt !== data.startAt">
              - {{ formatDate(data.endAt) }}</span
            >
          </div>
          <div
            class="o-training-details__hours"
            v-if="
              (data.format === 'instructor-led' || data.format === 'webinar') &&
              data.schedule
            "
          >
            {{ data.schedule.toUpperCase() }} (heure de Paris)
          </div>
          <div class="title-big">{{ data.formation.title }}</div>
        </div>

        <div class="o-training-details__aside">
          <div>
            <span v-if="data.format === 'instructor-led' && data.distance"
              >à
              <span v-if="data.distance > 1">{{ data.distance }}km</span>
              <span v-else>{{ data.distance * 1000 }}m</span>
            </span>
            <span v-else-if="data.format === 'e-learning'">En ligne</span>
          </div>
        </div>
      </div>

      <div class="o-training-details__grid">
        <div class="o-training-details__pack-horizontal">
          <div class="o-training-details__pack-vertical">
            <div class="o-training-details__pack-horizontal">
              <div class="o-training-details__details">
                <span
                  data-icon="timer"
                  v-if="data.formation.duration.hours"
                  class="icon-flex"
                  >{{ data.formation.duration.hours }}h
                  {{
                    data.startAt !== data.endAt && data.formation.duration.days > 1 &&!data.isPack
                      ? "sur " + data.formation.duration.days + " jours"
                      : ""
                  }}</span
                >
                <span
                  data-icon="people"
                  v-if="
                    (data.format === 'instructor-led' || data.format === 'webinar') &&
                    current_time < data.startAt
                  "
                  class="icon-flex"
                  >Il reste {{ data.remainingPlaces }}
                  <span v-if="data.remainingPlaces === 1"></span
                  ><span v-else>places</span></span
                >
                <span
                  data-icon="pin"
                  v-if="data.city && data.format === 'instructor-led'"
                  class="icon-flex"
                  >{{ data.city }}</span
                >
                <span
                  data-icon="screen"
                  v-if="data.format === 'e-learning' || data.format === 'webinar'"
                  class="icon-flex"
                >
                  <span v-if="data.format === 'webinar'">Webinaire</span>
                  <span v-else class="capitalize">{{ data.format }}</span>
                </span>
              </div>
      
              <!-- TODO add back when data.formation.program is available -->
              <!-- <form
                @submit.prevent="downloadProgram"
                v-if="!data.isPack && data.formation.program"
              >
                <submit
                  class="button--border button--small"
                  text="Télécharger le programme"
                  data-icon_after="download"
                  :loading="download"
                ></submit>
              </form> -->
            </div>
          <div class="o-training-details__content">
            <div v-if="data.formation.theme">
              <p class="subtitle">Thème</p>
              <div class="text">{{ data.formation.theme }}</div>
            </div>
    
    
            <div v-if="data.formation.objective && data.formation.objective.length > 1">
              <p class="subtitle">{{data.isPack ? "Fonctionnement" : "Objectifs"}}</p>
              <div class="text" v-html="ntodash(data.formation.objective)"></div>
            </div>
          </div>
          </div>

          <div v-if="data.isPack" class="o-training-details__image--pack">
            <img
              src="/img/elearning.png"
              alt="E Learning"
              v-if="data.format === 'e-learning'"
            />
            <img
              src="/img/webinar.jpg"
              alt="Webinar"
              v-else-if="data.format === 'webinar'"
            />
          </div>
        </div>

        <div v-if="data.condition">
          <p class="subtitle">Conditions</p>
          <div class="secondary" v-html="ntobr(data.condition)"></div>
        </div>

        <div v-if="data.format === 'webinar'">
          <p class="subtitle">Format</p>
          <div class="text">
            Live depuis
            <a href="https://zoom.us/download" target="_blank" class="link"
              >Zoom</a
            >
          </div>
        </div>
      </div>
      <div class="o-training-details__content">
        <div v-if="data.instructors && data.instructors.length > 0">
          <p class="subtitle">Intervenants</p>
          <ul class="text">
            <li v-for="(speaker, index) in data.instructors" :key="index">
              {{ speaker }}
            </li>
          </ul>
        </div>

        <div class="error">
          ATTENTION : Si vous souhaitez demander une prise en charge de votre
          formation à votre OPCO, il est impératif de leur envoyer une demande
          de prise en charge au moins un mois avant de vous y inscrire.
          N’hésitez pas à nous contacter pour toute information complémentaire.
        </div>

        <div v-if="data.location">
          <p class="subtitle">Adresse</p>
          <div>
            {{ data.location.name }}<br />
            {{ data.location.street }}<br />
            {{ data.location.zip }} {{ data.location.city }}<br />
          </div>
        </div>
      </div>

      <gmap
        :latitude="data.location.latLng[0]"
        :longitude="data.location.latLng[1]"
        :title="data.location.name"
        :zoom="16"
        class="o-training-details__map"
        v-if="data.location && data.location.latLng"
      ></gmap>
      <div v-if="!data.isPack" class="o-training-details__image">
        <img
          src="/img/elearning.png"
          alt="E Learning"
          v-if="data.format === 'e-learning'"
        />
        <img
          src="/img/webinar.jpg"
          alt="Webinar"
          v-else-if="data.format === 'webinar'"
        />
      </div>
      <div v-if="data.isPack" class="o-training-details__pack">
        <h3 class="subtitle">Mon pack</h3>
        <h4 class="o-training-details__pack-title">Formation imposée</h4>
        <div class="o-training-details__pack__training-entry--mandatory">
          <span data-icon="check" class="icon-flex"></span>
          <span
            >{{ data.formation.formation_required.title }}</span
          >
          <button
            class="o-training-details__pack__training-entry-cta button button--small button--border button--icon"
            data-icon_after="chevron-right"
			@click="openDetails({formation: data.formation.formation_required})"
          >
            Details
          </button>
        </div>
        <span class="o-training-details__pack-title">+</span>
        <h4 class="o-training-details__pack-title">les 5 formations de 7h au choix</h4>
        <div class="o-training-details__pack__entries">
          <div
            v-for="formation in currentPackFormationsPageFormations"
            class="o-training-details__pack__training-entry"
          >
            <label
              class="input--checkbox"
              :class="{
                disabled:
                  data.isComplete && !isFormationSelected(formation.id),
              }"
            >
              <input
                :name="'formation-'+formation.id"
                type="checkbox"
                :disabled="
                  data.isComplete && !isFormationSelected(formation.id)
                "
                :checked="isFormationSelected(formation.id)"
                @input="
                  (event) =>
                    event.target.checked
                      ? data.onSelectFormation(formation.id)
                      : data.onUnselectFormation(formation.id)
                " /><span data-icon="check" class="checkmark"></span
            ></label>
            <span>
              <label class="o-training-details__pack__training-entry-label" :for="'formation-'+formation.id" @click="isFormationSelected(formation.id) ? data.onUnselectFormation(formation.id) : data.onSelectFormation(formation.id)">{{ formation.formation.title }}</label><br />
              <span v-if="formation.doneBy.length > 0" class="o-training-details__pack__training-entry--notice"
                >Ils ont déjà réalisé cette formation : {{formation.doneBy.map(({firstname, lastname})=>firstname+" "+lastname).join(', ')}}</span
              >
            </span>
            <button
              class="o-training-details__pack__training-entry-details-cta button button--small button--border button--icon"
              data-icon_after="chevron-right"
              @click="openDetails(formation)"
            >
              Details
            </button>
          </div>
        <div v-if="packFormationsPagesCount>1" class="o-training-details__pack-pagination">
          <button
            class="button button--small button--border"
			      @click="previousPackFormationsPage"
            :disabled="packFormationsCurrentPage==0"
          >
          <
          </button>
          <button
            v-for="index in packFormationsPagesCount"
            class="button button--small button--border"
            :class="{active: index-1==packFormationsCurrentPage}"
			      @click="setPackFormationsCurrentPage(index-1)"
          >
          {{ index }}
          </button>
          <button
            class="button button--small button--border"
			      @click="nextPackFormationsPage"
            :disabled="packFormationsCurrentPage==packFormationsPagesCount-1"
          >
          >
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formationRepository from "@/repositories/formationRepository";

export default {
  name: "training-details",
  props: [
    "interface",
    "data",
    "onSelectedFormation",
    "onUnselectFormation",
    "selectedFormations",
    "data.isComplete",
  ],
  data() {
    return {
      download: false,
      packFormationsCurrentPage: 0,
      packFormationsPerPage: 10
    };
  },
  methods: {
    downloadProgram() {
      this.download = true;
      formationRepository.download(this.data.formation.id).then(
        (response) => {
          this.download = false;
        },
        (response) => {
          this.download = false;
        }
      );
    },
    openDetails(formation) {
      this.$popin("trainings-pack-details", {
        ...formation,
        isAdded: this.isFormationSelected(formation.id),
        onAdd: this.data.onSelectFormation,
        onRemove: this.data.onUnselectFormation,
        mandatory: formation.formation.id == this.data.formation.formation_required.id,
        isComplete: this.data.isComplete
      });
    },
    isFormationSelected(formationId) {
      return this.data.selectedFormations
        .map(({ id }) => id)
        .includes(formationId);
    },
    setPackFormationsCurrentPage(page){
      this.packFormationsCurrentPage = page;
      document.getElementsByClassName("o-training-details__pack-title")[0].scrollIntoView();
    },
    previousPackFormationsPage(){
      this.setPackFormationsCurrentPage(this.packFormationsCurrentPage-1);
    },
    nextPackFormationsPage(){
      this.setPackFormationsCurrentPage(this.packFormationsCurrentPage+1);
    },
  },
  computed: {
    current_time() {
      return this.$currentTime();
    },
    google() {
      return this.$store.getters.google();
    },
    packFormationsPagesCount(){
      return Math.ceil(this.data.formations.length / this.packFormationsPerPage);
    },
    currentPackFormationsPageFormations(){
      const offset = this.packFormationsCurrentPage*this.packFormationsPerPage;
      return this.data.formations.slice(offset, offset+this.packFormationsPerPage)
    }
  },
};
</script>

<style lang="scss">
@import "../../environment";
.o-training-details {
  &__content > * + * {
    margin-top: $space-m;
  }
  &__content {
    .subtitle {
      font-size: 90%;
    }
    .dash:after {
      content: "•";
      display: inline-block;
      margin: 0 1rem;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    .title-big {
      margin-top: $space-m;
    }
    @media #{$to-tablet} {
      flex-direction: column-reverse;
      & > * + * {
        margin-bottom: $space-s;
      }
    }
  }

  &__date {
    font-weight: bold;
  }
  &__hours {
    margin-top: $space-s;
    margin-bottom: $space-m;
    font-size: $font-s;
  }

  &__details {
    background: $c-background;
    border-radius: $border-radius;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: $space-s;
    & > * {
      display: inline-block;
      color: $c-primary;
      padding: $space-s $space-s;
      line-height: 0;
    }

    .accessible-version & {
      border: solid 1px $c-border;
    }
  }
  &__map {
    position: relative;
    padding-top: 33%;
    margin-top: $space;
  }
  &__image {
    margin-top: $space;
    .accessible-version & {
      border: solid 1px $c-border;
    }
    &--pack{
      flex: 1 0 50%;
      align-self: center;
    }
  }
  &__map {
    position: relative;
    padding-top: 33%;
    margin-top: $space;
  }
  &__image {
    margin-top: $space;
    img {
      max-width: none;
      width: 100%;
      border-radius: 3px;
    }
  }

  &__aside {
    @media #{$from-small} {
      text-align: right;
    }
    & > * + * {
      margin-top: $space-m;
    }
    .button {
      font-size: $font-m;
    }
  }

  &__back {
    float: right;
    color: $c-primary;
    cursor: pointer;
    &:before {
      font-size: $font-m;
      margin-right: $space-s;
    }
  }

  .subtitle + * {
    margin-top: 0.5rem;
  }

  &__aside {
    width: 30%;
  }

  &__pack {
    color: $c-primary;
	display: flex;
	flex-direction: column;
	gap: $space-s;
    &-title {
      font-size: $font-xl;
      font-weight: 700;
	  text-align: center;
    }
    &__entries {
      width: 100%;
    }
    &__training-entry {
      &,
      &--mandatory {
        display: flex;
        width: 100%;
        padding: $space-s $space-l $space-s $space-s;
        align-items: center;
        justify-content: start;
        gap: $space-m;
        &--notice {
          font-size: $font-s;
          color: $c-red;
        }
        :last-child {
          margin-left: auto;
        }
        &-label{
          cursor: pointer;
        }
        label.disabled{
          opacity: 0.25;    
        }
        @media #{$to-tablet} {
          gap: $space-s;
          padding-right: $space-s;
          &-details-cta {
            border: transparent;
            align-self: flex-end;
          }
        }
      }
      & {
        outline: 1px solid $c-background-lighter;
        &:hover {
          background-color: $c-background-lighter;
        }
      }
      &--mandatory {
        background-color: $c-background-darker;
      }
    }
    &-horizontal{
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-s;
      gap: $space-s;
      @media #{$to-tablet} {
        flex-direction: column;
      }
    }
    &-vertical{
      display: flex; flex-direction: column;
    }
    &-pagination{
      width: fit-content;
      margin-top: $space;
      margin-inline: auto;
      .active, .active:hover{
        background-color: $c-primary;
        color: white;
      }
      :disabled, :disabled:hover{
        color: lightgrey;
        border-color: lightgrey;
      }
    }
  }
}
</style>
