<template>
	<div class="o-trainings-list">
		<div class="block">
			<h1 class="title">{{interface.title}}</h1>
		</div>
    <ul class="o-tabs__tabs o-tabs-trainings">
      <button class="o-tabs__tab" :class="{ active: currentFilter.includes('all') }" @click="toggleFilter('all')" :disabled="loading || loadingFilters">Toutes les formations</button>
      <span> Filtrer mes formations: </span>
      <button class="button o-tabs__tab" :class="{ active: currentFilter.includes('completed') }" @click="toggleFilter('completed')" :disabled="loading || loadingFilters">Terminées</button>
      <button class="button o-tabs__tab" :class="{ active: currentFilter.includes('in_progress') }" @click="toggleFilter('in_progress')" :disabled="loading || loadingFilters">En cours</button>
      <button class="button o-tabs__tab" :class="{ active: currentFilter.includes('potential') }" @click="toggleFilter('potential')" :disabled="loading || loadingFilters">À venir</button>
      <button class="button o-tabs__tab" :class="{ active: currentFilter.includes('canceled') }" @click="toggleFilter('canceled')" :disabled="loading || loadingFilters">Annulées</button>
    </ul>
    <div v-if="count === 0" class="o-trainings-list__empty">
      <p class="title-big">Vous n'avez aucune formation souscrite</p>
      <p>Souscrivez à des formations pour les voir apparaître dans la liste.</p>
      <router-link to="/formations" class="button button--secondary">Réserver des formations</router-link>
    </div>
    <div v-else>
      <div v-if="formations.length === 0" class="o-trainings-list__empty">
        <p class="title-big">Aucune formation ne correspond au(x) filtre(s) sélectionné(s)</p>
      </div>
      <div v-else v-infinite-scroll.full="loadMore" class="o-trainings-list__trainings o-trainings-list__trainings--my-trainings">
        <trainingItem v-if="!loadingFilters" :data="formation" type="subscribed" class="o-trainings-list__training" v-for="(formation, index) in formations" :key="formation.id"></trainingItem>
        <loader class="action" v-if="loading"></loader>
      </div>
    </div>
  </div>
</template>

<script>
	import formationCourseRepository from '@/repositories/formationCourseRepository';

	export default {
		name: "my-trainings",
		props:['data','interface'],
		data(){
			return{
				loading: false,
        loadingFilters: false,
				formations: [],
				count: -1,
				params:{
					limit : 10,
					offset: 0,
          status: ''
        },
        currentFilter: "all"
      }
    },
  computed: {
    user() {
      return this.$user()
    }
  },
  methods: {
    toggleFilter(filter) {
      this.currentFilter = filter;
      this.applyCurrentFilter();
    },
    applyCurrentFilter() {
      this.loadingFilters = true;
      this.query(0)
    },
    searchFormations() {
      this.query(0);
    },
    openPopin(id) {
      this.$popin('training-external', id);
    },
    loadMore() {
      if (this.formations.length >= this.count || !this.formations.length)
        return false;
      this.query(this.params.offset + this.params.limit);
    },
    query(offset) {
	  if(this.loading === true) return;
      this.params.offset = offset;
      this.params.status = this.currentFilter != "all" ? this.currentFilter : undefined;
      this.loading = true;
      formationCourseRepository.getSubscribed(this.params).then(response => {
        this.count = response.count;
        this.formations = response.items;
        this.loading = false;
        this.loadingFilters = false;
      }).catch(e => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.searchFormations();
  }
}
</script>

<style lang="scss">
  @import '../../environment';
  .o-tabs {
    &-trainings {
      display: flex; gap: 1rem; overflow: auto; padding: 1rem 2rem; place-items: baseline;
      @media #{$to-tablet} { place-items: normal}
      li { 
        @media #{$to-tablet} { min-width: fit-content; }
      }
      .o-tabs__tab { margin-top: 0 !important; margin-left: 0 !important; padding: 1rem 1.5rem; height: 100%; line-height: 1; min-height: auto; font-size: 1.2rem; color: $c-primary; border-color: $c-primary !important; white-space: nowrap;
      &.active {
        background-color: $c-primary;
        color: white;
      }}
      span {
        margin-left: $space-m;
      }
      .button{
        text-transform: none;
      }
    }
  }
</style>