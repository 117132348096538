<template>
	<main class="v-migrate"></main>
</template>

<script>

	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "Register",
		computed:{
			user(){ return this.$user() }
		},
		mounted() {

			const token = new URLSearchParams(document.location.search).get("token")

			if(token){
				localStorage.clear();
				localStorage.setItem('from', this.$route.path);
				this.$router.push({path: "/connection?token="+token,})
			}

			let migrate = this.$route.params.id;

			if(migrate) {

				companyRepository.getRepresentatives().then(contacts=>{
					contacts.forEach(contact=>{
						if( contact.id === parseInt(migrate)  ){
							if(!contact.hasAccount)
								this.$popin('migrate', contact, false)
							else
								this.$alert({title:"Vous avez déjà créé votre espace personnel.", text:"Veuillez utiliser les identifiants de votre compte personnel pour vous connecter.<br/> <router-link to='/' class='button'>J’ai compris</router-link>"});
						}
					})
				})

			} else {

				this.$popin('alert-migrate', this.user, false)
			}
		}
	}
</script>
