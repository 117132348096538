import Vue from "vue";
import Router from "vue-router";

import Page from "@/views/Page.vue";
import Register from "@/views/Register.vue";
import Cms from "@/views/Cms.vue";
import Maintenance from "@/views/Maintenance.vue";
import Formation from "@/views/Formation.vue";
import FormationPack from "@/views/FormationPack.vue";
import Login from "@/views/Login.vue";
import Order from "@/views/Order.vue";
import eLearning from "@/views/eLearning.vue";
import Foad from "@/views/Foad.vue";
import Migrate from "@/views/Migrate.vue";
import News from "@/views/News.vue";

import store from "@/services/store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "home",
      path: "/",
      component: Page,
    },
    {
      name: "wait",
      path: "/wait",
    },
    {
      name: "positionnement",
      path: "/formations/positionnement",
      component: Page,
    },
    {
      name: "sondage",
      path: "/formations/sondage",
      component: Page,
    },
    {
      name: "register",
      path: "/inscription",
      component: Register,
    },
    {
      name: "migrate",
      path: "/migrate/:id",
      component: Migrate,
    },
    {
      name: "webinar",
      path: "/webinar/:id",
      component: eLearning,
    },
    {
      name: "foad",
      path: "/foad/:id",
      component: Foad,
    },
    {
      name: "maintenance",
      path: "/maintenance",
      component: Maintenance,
    },
    {
      name: "order",
      path: "/order/:id",
      component: Order,
    },
    {
      name: "documents_dico",
      path: "/documents/dictionnaire-immobilier",
      component: Page,
    },
    {
      name: "documents",
      path: "/documents/:section",
      component: Page,
    },
    {
      name: "formation",
      path: "/formations/formation/:id",
      component: Formation,
    },
    {
      name: "formation",
      path: "/formations/formation/:id/:action",
      component: Formation,
    },
    {
      name: "pack",
      path: "/formations/pack",
      component: FormationPack,
    },
    {
      name: "document",
      path: "/document/:id",
      component: Page,
    },
    {
      name: "news",
      path: "/news/:slug",
      component: News,
    },
    {
      name: "login",
      path: "/connection",
      component: Login,
    },
    {
      name: "auto-login",
      path: "/connection/:token",
      component: Login,
    },
    {
      name: "cms",
      path: "/edito/:slug",
      component: Cms,
    },
    {
      name: "experts_dico",
      path: "/experts/dictionnaire-immobilier",
      component: Page,
    },
    {
      name: "experts",
      path: "/experts/:slug",
      component: Cms,
    },
    {
      path: "/:page",
      component: Page,
    },
    {
      path: "/:page/:subpage",
      component: Page,
    },
    {
      name: "evenement",
      path: "/international/evenement/:id",
      component: Formation,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash, offset: { x: 0, y: 120 } });
        }, 500);
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {

  if (from.name !== "login") localStorage.setItem("from", from.path);

  if (from.query.email) localStorage.setItem("email", from.query.email);

  if (from.query.recover) localStorage.setItem("recover", true);

  if (from.query.key) localStorage.setItem("key", from.query.key);

  if (store.getters.popin()) store.commit("popin", false);

  next();
});

export default router;
