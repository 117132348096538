<template>
  <div class="o-trainings-pack-details" v-if="data">
    <h1 class="title-big center">Détails Formation</h1>

    <div class="o-trainings-pack-details__theme-heading">
      Compétences transversales
    </div>
    <div class="o-trainings-pack-details__theme-content">
      <span class="icon-flex" data-icon="line-break">
        {{ data.formation.theme }}
      </span>
    </div>
    <div class="o-trainings-pack-details__content-main">
      <div class="o-trainings-pack-details__heading">
        <div>
          <div
            class="o-trainings-pack-details__date primary"
            v-if="data.format === 'instructor-led' || data.format === 'webinar'"
          >
            {{ formatDate(data.startAt) }}
            <span v-if="data.endAt && data.endAt !== data.startAt">
              - {{ formatDate(data.endAt) }}</span
            >
          </div>
          <div
            class="o-trainings-pack-details__hours"
            v-if="
              (data.format === 'instructor-led' || data.format === 'webinar') &&
              data.schedule
            "
          >
            {{ data.schedule.toUpperCase() }} (heure de Paris)
          </div>
          <div class="title-big">{{ data.formation.title }}</div>
        </div>
      </div>

      <div class="o-trainings-pack-details__grid">
        <div class="o-trainings-pack-details__details">
          <span
            data-icon="timer"
            v-if="data.formation.duration.hours"
            class="icon-flex"
            >{{ data.formation.duration.hours }}h
            {{
              data.startAt !== data.endAt && data.formation.duration.days > 1
                ? "sur " + data.formation.duration.days + " jours"
                : ""
            }}</span
          >
          <span
            data-icon="people"
            v-if="
              (data.format === 'instructor-led' || data.format === 'webinar') &&
              current_time < data.startAt
            "
            class="icon-flex"
            >Il reste {{ data.remainingPlaces }}
            <span v-if="data.remainingPlaces === 1"></span
            ><span v-else>places</span></span
          >
          <span
            data-icon="pin"
            v-if="data.city && data.format === 'instructor-led'"
            class="icon-flex"
            >{{ data.city }}</span
          >
          <span
            data-icon="screen"
            v-if="data.format === 'e-learning' || data.format === 'webinar'"
            class="icon-flex"
          >
            <span v-if="data.format === 'webinar'">Webinaire</span>
            <span v-else class="capitalize">{{ data.format }}</span>
          </span>
        </div>
        <!-- <form
                @submit.prevent="downloadProgram"
                v-if="!data.isPack && data.formation.program"
              >
                <submit
                  class="button--border button--small"
                  text="Télécharger le programme"
                  data-icon_after="download"
                  :loading="download"
                ></submit>
              </form> -->
      </div>

      <div class="o-trainings-pack-details__content">

        <div v-if="data.formation.objective">
          <p class="subtitle">Objectifs</p>
          <div class="text" v-html="ntodash(data.formation.objective)"></div>
        </div>

        <div v-if="data.condition">
          <p class="subtitle">Conditions</p>
          <div class="secondary" v-html="ntobr(data.condition)"></div>
        </div>

        <div v-if="data.format === 'webinar'">
          <p class="subtitle">Format</p>
          <div class="text">
            Live depuis
            <a href="https://zoom.us/download" target="_blank" class="link"
              >Zoom</a
            >
          </div>
        </div>
      </div>
      <div class="o-trainings-pack-details__content">
        <div v-if="data.instructors && data.instructors.length > 0">
          <p class="subtitle">Intervenants</p>
          <ul class="text">
            <li v-for="(speaker, index) in data.instructors" :key="index">
              {{ speaker }}
            </li>
          </ul>
        </div>

        <div class="error">
          ATTENTION : Si vous souhaitez demander une prise en charge de votre
          formation à votre OPCO, il est impératif de leur envoyer une demande
          de prise en charge au moins un mois avant de vous y inscrire.
          N’hésitez pas à nous contacter pour toute information complémentaire.
        </div>

        <div v-if="data.location">
          <p class="subtitle">Adresse</p>
          <div>
            {{ data.location.name }}<br />
            {{ data.location.street }}<br />
            {{ data.location.zip }} {{ data.location.city }}<br />
          </div>
        </div>
      </div>

      <gmap
        :latitude="data.location.latLng[0]"
        :longitude="data.location.latLng[1]"
        :title="data.location.name"
        :zoom="16"
        class="o-trainings-pack-details__map"
        v-if="data.location && data.location.latLng"
      ></gmap>
      <div class="o-trainings-pack-details__image">
        <img
          src="/img/elearning.png"
          alt="E Learning"
          v-if="data.format === 'e-learning'"
        />
        <img
          src="/img/webinar.jpg"
          alt="Webinar"
          v-else-if="data.format === 'webinar'"
        />
      </div>
      <div v-if="!data.mandatory" class="center">
        <button v-if="data.isAdded" @click="handleRemove" class="button">
          Retirer cette formation
        </button>
        <button v-else-if="!data.isComplete" @click="handleAdd" class="button">
          Ajouter cette formation
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import formationRepository from "@/repositories/formationRepository";

export default {
  name: "trainings-pack-details",
  props: ["data"],
  data() {
    return {
      download: false
    }
  },
  methods: {
    handleAdd() {
      this.data.onAdd(this.data.id);
      this.$emit('close')
    },
    handleRemove(){
      this.data.onRemove(this.data.id);
      this.$emit('close')
    },
    downloadProgram() {
      this.download = true;
      formationRepository.download(this.data.formation.id).then(
        (response) => {
          this.download = false;
        },
        (response) => {
          this.download = false;
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../environment";
.o-trainings-pack-details {
  text-align: left;
  &__content {
    &-main{
      & > * + * {
        margin-top: $space-m;
      }
      border-radius: 0 0 0.7rem 0.7rem;
      padding: 0 $space $space-s $space;
      border: 2px solid $c-background;
      border-top: none;
    }
    .subtitle {
      font-size: 90%;
    }
    .dash:after {
      content: "•";
      display: inline-block;
      margin: 0 1rem;
    }
  }

  &__theme{
    &-heading{
      font-family: var(--font-card);
      border-radius: 0.7rem 0.7rem 0 0;
      background-color: $c-primary;
      padding: $space-s $space $space-s $space;
      color: white;
      text-transform: uppercase;
    }
    &-content{
      color: $c-primary;
      padding: $space-s $space $space-s $space;
      margin: 0;
      border: 2px solid $c-background;
      border-top: none;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    .title-big {
      color: black;
      margin-top: $space-m;
    }
    @media #{$to-tablet} {
      flex-direction: column-reverse;
      & > * + * {
        margin-bottom: $space-s;
      }
    }
  }

  &__date {
    font-weight: bold;
  }
  &__hours {
    margin-top: $space-s;
    margin-bottom: $space-m;
    font-size: $font-s;
  }

  &__details {
    background: $c-background;
    border-radius: $border-radius;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    & > * {
      display: inline-block;
      color: $c-primary;
      padding: $space-s $space-s;
      line-height: 0;
    }

    .accessible-version & {
      border: solid 1px $c-border;
    }
  }
  &__map {
    position: relative;
    padding-top: 33%;
    margin-top: $space;
  }
  &__image {
    margin-top: $space;
    .accessible-version & {
      border: solid 1px $c-border;
    }
  }
  &__map {
    position: relative;
    padding-top: 33%;
    margin-top: $space;
  }
  &__image {
    margin-top: $space;
    img {
      max-width: none;
      width: 100%;
      border-radius: 3px;
    }
  }

  &__aside {
    @media #{$from-small} {
      text-align: right;
    }
    & > * + * {
      margin-top: $space-m;
    }
    .button {
      font-size: $font-m;
    }
  }

  &__back {
    float: right;
    color: $c-primary;
    cursor: pointer;
    &:before {
      font-size: $font-m;
      margin-right: $space-s;
    }
  }

  .subtitle + * {
    margin-top: 0.5rem;
  }

  &__grid {
    @media #{$from-small} {
      display: flex;
      justify-content: space-between;
    }
    @media #{$to-tablet} {
      & > * + * {
        margin-top: $space-m;
      }
    }
  }
  &__aside {
    width: 30%;
  }
}
</style>
