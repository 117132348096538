<template>
    <div class="s-contact-settings">
        <button class="o-popin__close" @click="tryClose">X</button>
        <div v-show="view == 'infos'">
            <div class="s-contact-settings__heading">
                <h2 class="title">Modifier un collaborateur</h2>
                <a v-if="data.permissionsCanBeEdited" class="s-contact-settings__navigation--left" @click="setView('permissions')">Gérer les droits du collaborateur</a>
            </div>
            <contact-edit :data="data"></contact-edit>
        </div>
        <div class="s-contact-settings__heading" v-show="view == 'permissions'">
            <div class="s-contact-settings__heading">
                <h2 class="title">Gérer les droits spécifiques</h2>
                <a class="s-contact-settings__navigation--right" @click="setView('infos')">Retour →</a>
            </div>
            <edit-contact-permissions :data="data" :onDirtyChange="handlePermissionsDirtyChange" :dirtyWarning="triedClosingWithDirtySettings"></edit-contact-permissions>
        </div>
    </div>
</template>
<script>
export default {
    name: "contact-settings",
    props: ["data"],
    data(){
        return {
            view: 'infos',
            permissionsSettingsAreDirty: false,
            triedClosingWithDirtySettings: false
        }
    },
    methods: {
        setView(view){
            if(this.permissionsSettingsAreDirty){
                this.triedClosingWithDirtySettings = true;
            }
            else{
                this.view = view
            }
        },
        handlePermissionsDirtyChange(dirty){
            this.permissionsSettingsAreDirty = dirty;
            if(!dirty)
                this.triedClosingWithDirtySettings = false;
        },
        tryClose(){
            if(this.permissionsSettingsAreDirty){
                this.triedClosingWithDirtySettings = true;
            }
            else{
                this.$emit("close");
            }
        }
    },
}
</script>
<style lang="scss">
@import '../../environment';

.s-contact-settings{
    h2, &__heading{
        margin-bottom: $space-m;
    }

    &__navigation{
        &--left, &--right{
            font-weight: 700;
            font-size: 16px;
            color: $c-primary;
            text-decoration: underline;
            cursor: pointer;
            display: block;
            width: fit-content;
        }
        &--left{
            margin-right: auto;
        }
        &--right{
            margin-left: auto;
        }
    }
}
</style>